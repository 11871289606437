// Initializing globals
var list = [];
var posiQuick = false;
var zoom = 80;
var zoomTxt = 35;
var valoresPermisibles = false;

var errorB = document.querySelector("#error-2b");
var errorL = document.querySelector("#error-2l");
var errorQ = document.querySelector("#error-2q");
